import React from 'react';
import '../assets/css/contactMenu.css';
import instagramLogo from '../assets/img/instagram-icon.svg';
import whatsappLogo from '../assets/img/whatsapp-icon.svg';
import facebookLogo from '../assets/img/facebook-icon.svg';
import tiktokLogo from '../assets/img/tiktok-icon.svg';

function ContactMenu() {
    return (
        <div className="contact-menu">
            <a href="https://wa.me/+48730634203" target="_blank" rel="noopener noreferrer">
                <img src={whatsappLogo} alt="WhatsApp" />
            </a>
            <a href="https://www.instagram.com/polissya.granite/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/polissya.granite" target="_blank" rel="noopener noreferrer">
                <img src={facebookLogo} alt="WhatsApp" />
            </a>
            <a href="https://www.tiktok.com/@polissya.granite" target="_blank" rel="noopener noreferrer">
                <img src={tiktokLogo} alt="WhatsApp" />
            </a>
        </div>
    );
}

export default ContactMenu;
