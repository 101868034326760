import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageImage({ lang }) {
    const [t, i18n] = useTranslation("global");

    const languageMap = {
        en: {
            src: "https://img.icons8.com/color/48/great-britain-circular.png",
            alt: "great-britain-circular",
            text: "EN"
        },
        ua: {
            src: "https://img.icons8.com/color/48/ukraine-circular.png",
            alt: "ukraine-circular",
            text: "UA"
        },
        pl: {
            src: "https://img.icons8.com/color/48/poland-circular.png",
            alt: "poland-circular",
            text: "PL"
        },
        de: {
            src: "https://img.icons8.com/color/48/germany-circular.png",
            alt: "germany-circular",
            text: "DE"
        }
    };

    const languageData = languageMap[lang];

    if (!languageData) {
        return null; // or return some default image if lang is not found
    }

    return (
        <>
            <img className="lang-img" width="25" height="25" src={languageData.src} alt={languageData.alt} />{languageData.text}
        </>
    );
}

export default LanguageImage;
