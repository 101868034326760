import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/css/navbar.css';
import logo from "../assets/img/main-logo.svg";
import { logout } from '../firebase-communication/firebase-auth';
import LanguageImage from './LanguageImage';

function Header({ isAdmin }) {
    const [t, i18n] = useTranslation("global");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    const handleClickOutside = (event) => {
        if (
            (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
            (menuRef.current && !menuRef.current.contains(event.target))
        ) {
            setIsDropdownOpen(false);
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavigation = (url) => {
        window.location.href = url;
    };

    return (
        <>
            <header className="navbar navbar-fixed">
                <div className="navbar-container container">
                    <input
                        type="checkbox"
                        id="menu-toggle"
                        checked={isMenuOpen}
                        onChange={toggleMenu}
                        className="menu-toggle"
                    />
                    <label htmlFor="menu-toggle" className="hamburger">
                        <span className="hamburger-line"></span>
                        <span className="hamburger-line"></span>
                        <span className="hamburger-line"></span>
                    </label>
                    <a href="/" className="logo">
                        <img src={logo} alt="Logo" />
                    </a>
                    
                    <nav ref={menuRef} className={`menu ${isMenuOpen ? 'open' : ''}`}>
                        <ul className="nav_list">
                            <li className="nav_list_item" onClick={() => handleNavigation('/communication')}>
                                {t("header.contacts")}
                            </li>
                            <li className="nav_list_item" onClick={() => handleNavigation('/order')}>
                                {t("header.menu7")}
                            </li>
                            <li ref={dropdownRef} onClick={toggleDropdown} className="nav_list_item dropdown">
                                <LanguageImage lang={i18n.language}/>
                                {isDropdownOpen && (
                                    <ul className="dropdown-content">
                                        <li className="dropdown-lang" onClick={() => handleLanguageChange('en')}>
                                            <img className="lang-img" width="25" height="25" src="https://img.icons8.com/color/48/great-britain-circular.png" alt="great-britain-circular"/>EN
                                        </li>
                                        <li className="dropdown-lang" onClick={() => handleLanguageChange('ua')}>
                                            <img className="lang-img" width="25" height="25" src="https://img.icons8.com/color/48/ukraine-circular.png" alt="ukraine-circular"/>UA
                                        </li>
                                        <li className="dropdown-lang" onClick={() => handleLanguageChange('pl')}>
                                            <img className="lang-img" width="25" height="25" src="https://img.icons8.com/color/48/poland-circular.png" alt="poland-circular"/>PL
                                        </li>
                                        <li className="dropdown-lang" onClick={() => handleLanguageChange('de')}>
                                            <img className="lang-img" width="25" height="25" src="https://img.icons8.com/color/48/germany-circular.png" alt="germany-circular"/>DE
                                        </li>
                                    </ul>
                                )}
                            </li>
                            {isAdmin && (
                                <li className="nav_list_item" onClick={logout}>
                                    {t("header.logout")}
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </header>
            <div className='header-helper'></div>
        </>
    );
}

export default Header;
